<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <h1 v-html="title"></h1>
    <h4 v-html="openFileTitle"></h4>
    <p v-html="openFileText"></p>
    <h4 v-html="saveFileTitle"></h4>
    <p v-html="saveFileText"></p>
    <p v-html="moreInfo"></p>
  </v-card>
</template>

<script>
import axios from "axios";
import i18n from "../../../i18n";
export default {
  props: ["parameter"],
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$t(this.parameter.title);
    },
    openFileTitle() {
      return this.$t(this.parameter.openFileTitle);
    },
    openFileText() {
      return this.$t(this.parameter.openFileText);
    },
    saveFileTitle() {
      return this.$t(this.parameter.saveFileTitle);
    },
    saveFileText() {
      return this.$t(this.parameter.saveFileText);
    },
    moreInfo() {
      return this.$t(this.parameter.moreInfo);
    },
  },
  methods: {},
  async created() {},
};
</script>